<template>
  <div class="page-form recharge">
    <el-card class="form-card">
      <el-form :model="form" label-width="100px" ref="form" :rules="formRules">
      
        <el-form-item :label="$t('ti-xian-bi-zhong')" prop="coinType" >
          <el-select v-model="form.coinType" @change="changeCoinType">
            <el-option
              v-for="(item, i) in coinTypeList"
              :key="i"
              :value="item.key"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('ti-xian-wang-luo')" prop="network" >
          <el-select v-model="form.network">
            <el-option
              v-for="(item, i) in linkList"
              :key="i"
              :value="item.key"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('ti-xian-di-zhi')" prop="address" required>
          <el-input
            v-model="form.address"
            :placeholder="$t('qing-shu-ru-ti-xian-di-zhi-0')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('ti-xian-jin-e')" prop="number" required>
          <el-input
            v-model="form.number"
            clearable
            :placeholder="$t('qing-shu-ru-ti-xian-jine')"
          >
            <template slot="suffix">
              {{ form.coinName }}
            </template>
          </el-input>
          <div class="form-info">
            {{ $t('qian-bao-yue') }}
            ${{ userMoney }}
            <!-- <span class="blue">123.4412 {{form.coinName}}</span> -->
          </div>
        </el-form-item>
        <el-form-item :label="$t('shi-ji-dao-zhang')">
          <el-input v-model="realMoney" readonly="">
            <template slot="suffix">
              {{ form.coinName }}
            </template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('shou-xu-fei-0')">
          <el-input v-model="fee" readonly="">
            <template slot="suffix"> USDT </template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('zi-jin-mi-ma')" prop="password" required>
          <el-input
            type="password"
            v-model="form.password"
            :placeholder="$t('qing-shu-ru-ti-xian-di-zhi-0')"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button class="submit" type="primary" @click="submit">{{
            $t('ti-jiao')
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import { getWalletInfo, addWithdraw } from '@/api/user'
export default {
  data() {
    return {
      form: {
        number: '',
        address: '',
        password: '',
      },
      userMoney: 0,
      typeList: [
        {
          value: 1,
          label: this.$t('jia-mi-huo-bi')
        },
        {
          value: 2,
          label: this.$t('yin-hang-ka')
        }
      ],
      fee: 0,
      formRules: {},
      coinTypeList: [
        {
          key: 'usdt',
          name: 'USDT',
          linkList: [
            {
              key: 'trc20',
              name: 'TRC20'
            },
          ]
        }
      ],
      linkList: []
    }
  },
  computed: {
    realMoney() {
      return this.form.number ? parseFloat(this.form.number).toFixed(2) : '0.00'
    }
  },
  mounted() {
    this.linkList = JSON.parse(JSON.stringify(this.coinTypeList[0].linkList))
    this.form.network = this.linkList[0].key
    this.form.coinType = this.coinTypeList[0].key
    this.form.coinName = this.coinTypeList[0].name
    this.init()
  },
  methods: {
    init() {
      getWalletInfo().then((res) => {
        let data = res.data.Items[0]
        this.userMoney = data.Balance
      })
    },
    changeCoinType(val) {
      let index = this.coinTypeList.findIndex((v) => {
        return v.key === val
      })
      this.linkList = JSON.parse(
        JSON.stringify(this.coinTypeList[index].linkList)
      )
      this.form.network = this.linkList[0].key
      this.form.coinName = this.coinTypeList[index].name
    },
     submit() {
      this.$refs.form.validate((vali) => {
        if (vali) {
          addWithdraw({
            Price: parseFloat(this.form.number),
            PayPassword: this.form.password,
            Address: this.form.address,
            Currency: 'USDT-TRC20',
          }).then(res => {
            if (res.code == 0) {
              this.$message.success(this.$t('ti-jiao-cheng-gong'))
              this.form.address = ''
              this.form.password = ''
              this.form.number = ''
              this.$refs.form.resetFields()
            } else {
              this.$message.warning(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>